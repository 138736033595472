import React from "react";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderTech.css";

 
class SliderTech extends React.Component {
  render() {
    var settings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };
    return (
      <Slider {...settings}>
        <div>
          <img class="techLogo" src={require("../../assets/img/react.svg")} alt="" />
        </div>
        <div>
          <img class="techLogo" src={require("../../assets/img/git.svg")} alt="" />
        </div>
        <div>
          <img class="techLogo" src={require("../../assets/img/wordpress-blue.svg")} alt="" />
        </div>
        <div>
          <img class="techLogo" src={require("../../assets/img/html5c.svg")} alt="" />
        </div>
        <div>
          <img class="techLogo" src={require("../../assets/img/css-5.svg")} alt="" />
        </div>
        <div>
          <img class="techLogo" src={require("../../assets/img/javascript-4.svg")} alt="" />
        </div>
      </Slider>
    );
  }
}

export default SliderTech;
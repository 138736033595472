import React from "react";
import Slider from "react-slick";


// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SimpleSlider.css";
 
class SimpleSlider extends React.Component {
  render() {
    var settings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
    };
    return (
      <Slider {...settings}>
        <div>
          <a href="https://realgreendeal.eu/"><img class="img-fluid bw-logo" src={require("../../assets/img/relgreendeal.png")} alt="" /></a>
        </div>
        <div>
          <a href="https://taxpledge.eu"><img class="img-fluid bw-logo" src={require("../../assets/img/TaxJusticeEurope.png")} alt="" /></a>
        </div>
        <div>
          <a href="http://access-info.org"><img class="img-fluid bw-logo" src={require("../../assets/img/access-info_logo.png")} alt="" /></a>
        </div>
        <div>
          <a href="https://www.asktheeu.org/"><img class="img-fluid bw-logo" src={require("../../assets/img/askeu_logo.jpg")} alt="" /></a>
        </div>
        <div>
          <a href="https://threestones.guide/"><img class="img-fluid bw-logo" src={require("../../assets/img/3stones_logo.png")} alt="" /></a>
        </div>
        <div>
          <a href="https://www.changeofdirection.eu/"><img class="img-fluid bw-logo" src={require("../../assets/img/cod_logo.png")} alt="" /></a>
        </div>
        <div>
          <a href="http://rti-rating.org"><img class="img-fluid bw-logo" src={require("../../assets/img/rti_logo.png")} alt="" /></a>
        </div>
        <div>
          <a href="http://riparteilfuturo.it"><img class="img-fluid bw-logo" src={require("../../assets/img/riparte_logo.png")} alt="" /></a>
        </div>
      </Slider>
    );
  }
}

export default SimpleSlider;
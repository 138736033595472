import React from 'react';
import './App.css';
// import ComponentTpl from './components/componentTpl/ComponentTpl';
import BootstrapTpl from './components/BootstrapTpls/BootstrapTpl';

export default class App extends React.Component {
  render() {
    return (
    <div className="App">
      <BootstrapTpl/>
    </div>
  );
}
}